import React from 'react';
import './Jumbo.scss'
import {useTheme} from "@mui/material";


const Jumbo = () => {
  const theme = useTheme()

  return (
    <div className={`Jumbo ${theme.palette.mode === 'dark' ? 'dark' : ''}`} >

    </div>
  );
}


export default Jumbo;
