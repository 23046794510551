import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {store} from './redux/store';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import theme from "./layout/theme/theme";
import {CssBaseline} from "@mui/material";
import {ThemeProvider} from '@mui/material/styles';
import {queryClient} from "./react-query/client"
import {QueryClientProvider} from 'react-query'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';



const container = document.getElementById('root')!;
const root = createRoot(container);
//
// const disabledRoutes = [
//   'login'                       ,
//   'register'                    ,
//   'forgotPassword'              ,
//   'resetPassword'               ,
//   'layout'                      ,
//   'action_email_2fa'            ,
//   'action_email_2fa_verify'     ,
//   'action_email_2fa_email'      ,
//   'action_email_activate_email' ,
//   'action_email_activate_show'  ,
//   'magic-link-login'            ,
//   'magic-link-message'          ,
//   'magic-link-email'            ,
// ].map( s => `/${s}`)
//
// const path = `${window.location.pathname}`
// if(!disabledRoutes.includes(path)) {
//   root.render(
//     <React.StrictMode>
//       <ThemeProvider theme={theme}>
//         <CssBaseline/>
//         <Provider store={store}>
//           <QueryClientProvider client={queryClient}>
//             <App/>
//           </QueryClientProvider>
//         </Provider>
//       </ThemeProvider>
//     </React.StrictMode>
//   );
// }

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <App/>
        </QueryClientProvider>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);




// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
