
export function cleanParams(str:string) {
  return str.replace(new RegExp("({[A-z0-9_\\-]+})", "g"), "");
}

export function interpolate(str:string|null|undefined, params: any, prefix = ""):string {
  const keys = Object.keys(params);
  // if no string is given we generate one ( params = {foo:'bar', baz:'wth'} will generate {foo}:{baz})
  // it will provide a unique id for models
  const stringToDecorate = str || keys.map(v => "{" + prefix + v + "}").join(":");
  // it will turn path/to/{id} to path/to/123
  const result = keys.reduce((prev, current) => {
    const elm_val = params[current];
    if (elm_val !== null && typeof elm_val === "object" && Array.isArray(elm_val) === false) {
      return interpolate(prev, elm_val, current + ".");
    }
    if (Array.isArray(elm_val)) {
      return prev.replace(
        new RegExp("{" + prefix + current + "}", "g"),
        "[" + elm_val.map(item => typeof item === "object" ? interpolate(null, item) : item).join("|") + "]"
      );
    }
    return prev.replace(new RegExp("{" + prefix + current + "}", "g"), elm_val);
  }, stringToDecorate);
  // if params are missing we remove them
  // path/to/123/{anotherId} => path/to/123/
  return prefix ? result : cleanParams(result);
}