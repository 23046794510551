import React from 'react';

const FlexCenter = (props:{children: any}) => {
  return (
    <div  style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
      {props.children}
    </div>
  );
}


export default FlexCenter;
