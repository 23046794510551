import {config} from "../../../config/config";
//import {interpolate} from "../../../utils/string";

export const searchGlossary = (term:string) => {
  if(term.length < 3) {
    return Promise.resolve([])
  }
  return fetch(config.glossary.search, {
    method: 'post',
    headers: {
      'Content-Type':'application/json'
    },
    body: JSON.stringify({
      term
    })
  })
    .then(response => {
      if(response.ok) {
        return response.json()
      }
      return Promise.reject(response)
    })
}
export const getGlossaryWord = (slug:string) => {
  if(!slug) {
    return Promise.reject()
  }
  return fetch(config.glossary.word.replace('{slug}',slug))
    .then(response => {
      if(response.ok) {
        return response.json()
      }
      return Promise.reject(response)
    })
}