import React, {useState} from 'react';
import {Autocomplete, Dialog, DialogContent, TextField} from "@mui/material";
import useDebounce from "../../utils/hooks/useDebounce";
import CircularProgress from "@mui/material/CircularProgress";
import {useQuery} from "react-query";
import {GlossaryItem} from "../glossary/glossary.typedefs";
import {searchGlossary} from "../glossary/api/glossary";
import './SearchDialog.scss'
//import Markdown from "../../layout/markdown/Markdown";
import theme from "../../layout/theme/dark-theme";
import {ThemeProvider} from "@mui/material/styles";
import {truncateText} from "./utils/string";
import GlossaryWord from "../glossary/GlossaryWord";

interface SearchDrawerProps {
  open: boolean
  toggle: (state?: boolean) => void
}

const SearchDialog = ({open, toggle}: SearchDrawerProps) => {
  const [autoCompleteOpen, setOpen] = React.useState(false);
  const [term, setTerm] = useState('')
  const [glossary, setGlossary] = useState('')
  const debouncedTerm = useDebounce(term, 500)
  const {isLoading, data = []} = useQuery<GlossaryItem[]>(`glossary:search:${debouncedTerm}`, () => {
    return searchGlossary(debouncedTerm)
  })


  return (
    <ThemeProvider theme={theme}>
    <Dialog className="Header-Search-Dialog" open={open}
            fullWidth={true}
            maxWidth="md"
            onClose={() =>{
              setGlossary('')
              toggle(false)
            }}>
      <DialogContent>

        {!!glossary ? (
          <GlossaryWord word={glossary}/>
        ): (
          <Autocomplete
            className={"Search-Auto-Complete"}
            //sx={{ width: 300 }}
            open={autoCompleteOpen}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            onChange={(_a, option) => {
              if(!option) return null;
              setGlossary(option.slug)
            }}
            onInputChange={(event: any, value) => {
              setTerm(value)
            }}
            isOptionEqualToValue={(option, value) => {
              return true
            }}
            filterOptions={(data) => data}
            getOptionLabel={(option) => option.title}
            options={data}
            loading={isLoading}
            renderOption={(props, option, {selected}) => {
              return (
                <li {...props}
                  //onClick={() => setGlossary(option.slug)}
                    className={`search-auto-complete-option ${selected ? 'selected': ''}`} style={{display: 'block'}}>
                  <p>
                    <strong>{option.title}</strong><br />
                    {truncateText(option.text, 140)}
                  </p>
                  {/*<Markdown children={option.content}/>*/}
                  {/*<div dangerouslySetInnerHTML={{__html: option.html}}></div>*/}
                </li>
              )
            }}

            renderInput={(params) => (
              <TextField
                {...params}
                label="Rechercher"
                autoFocus
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {isLoading ? <CircularProgress color="inherit" size={20}/> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        )}




      </DialogContent>
    </Dialog>
    </ThemeProvider>
  );
}


export default SearchDialog;
