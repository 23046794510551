import React from 'react';
import Home from "../../features/home/Home";

const HomePage = () => {
  return (
    <Home />
  );
}


export default HomePage;
