import React from 'react';
import Jumbo from "./jumbo/Jumbo";

import Container from "@mui/material/Container";
import LessonIndex from "./lessons/LessonIndex";
import WelcomeText from "./welcome/WelcomeText";

const Home = () => {
  return (
    <div>
      <Jumbo />

      <Container>
      <WelcomeText />
        <LessonIndex />
      </Container>
    </div>
  );
}


export default Home;
