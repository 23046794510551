import React from 'react';
import './WelcomeText.scss'
const WelcomeText = () => {
  return (
    <div className="welcome-text">
      <h3>Ce site est en travaux</h3>
      <p>Il n'a pour but que d'améliorer l'interface du site http://www.bascorama.com</p>
      <p>Tous les droits sont réservés à ce meme site http://www.bascorama.com</p>
    </div>
  );
}


export default WelcomeText;
