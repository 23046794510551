import * as React from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@mui/material/Toolbar';
//import Button from '@mui/material/Button';
//import IconButton from '@mui/material/IconButton';

import Typography from '@mui/material/Typography';
import {useNavigate} from "react-router-dom";
import './Header.scss'
import SearchDialog from "../../features/search/SearchDialog";
import {useEffect} from "react";
import {Icon, IconButton} from "@mui/material";
//import {Icon} from "@mui/material";

function Header({ title, searchOpen, setSearchOpen}:{ title: string, searchOpen: boolean, setSearchOpen: (state?: boolean) => void}) {



  const navigate = useNavigate()

  useEffect(() => {
    const showSearchBar = (e:KeyboardEvent) => {
      if ((e.shiftKey && e.key === '/') ||(e.shiftKey && e.key === '/') ) {
        e.preventDefault();
        setSearchOpen();
      }
    }
    window.document.addEventListener('keydown', showSearchBar);

    return () => {
      window.document.removeEventListener('keydown', showSearchBar)
    }
  }, [setSearchOpen])
  return (
    <React.Fragment>
      <SearchDialog open={searchOpen} toggle={setSearchOpen} />
      <Toolbar className="main-header" >
        {/*<Button size="small">Subscribe</Button>*/}
        <Typography
          onClick={() => navigate('/')}
          component="h2"
          variant="h5"
          color="inherit"
          className="app-title"
          noWrap
          sx={{ flex: 1, cursor: 'pointer' }}
        >
          {title}
        </Typography>
        <IconButton onClick={()=> setSearchOpen(true)}>
          <Icon style={{color: '#FFF'}}>search</Icon>
        </IconButton>
      </Toolbar>
    </React.Fragment>
  );
}

Header.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
};

export default Header;