import {config} from "../../../config/config";
import {interpolate} from "../../../utils/string";

export const getLessons = () => {
  return fetch(config.lesson.list)
    .then(response => {
      if(response.ok) {
        return response.json()
      }
      return Promise.reject(response)
    })
}
export const getLastLessons = () => {
  return fetch(config.lesson.lastLessons)
    .then(response => {
      if(response.ok) {
        return response.json()
      }
      return Promise.reject(response)
    })
}
export const getLesson = (id: string) => {
  return fetch(interpolate(config.lesson.one, {id}))
    .then(response => {
      if(response.ok) {
        return response.json()
      }
      return Promise.reject(response)
    })
}
interface LessonBodyParams {
  markdown: string
  index: number
  title: string
  shortTitle: string
}
export const setLessonMarkdown = (id: string, bodyParams: LessonBodyParams = {markdown: '', index: 0, title: '', shortTitle: ''}) => {
  return fetch(interpolate(config.lesson.one, {id}), {
    method: 'put',
    headers: {
      'Content-Type':'application/json'
    },
    body: JSON.stringify(bodyParams),
  })
    .then(response => {
      if(response.ok) {
        return response.json()
      }
      return Promise.reject(response)
    })
}