import React from 'react';
import {useQuery} from "react-query";
import {GlossaryItem} from "./glossary.typedefs";
import {getGlossaryWord} from "./api/glossary";
import CircularProgress from "@mui/material/CircularProgress";

import './GlossaryWord.scss'
import Grid from "@mui/material/Grid";
import FlexCenter from "../../layout/flex/FlexCenter";

const GlossaryWord = ({word}:{word: string}) => {
  const {status, data} = useQuery<GlossaryItem>(`glossary:word:${word}`, () => {
    return getGlossaryWord(word)
  })
  if(status !== 'success') {
    return <CircularProgress/>
  }


  return (

    <Grid container spacing={3}>
      <Grid item xs={12} sm={4}>
        <FlexCenter>
          <img alt={data.title} src={data.image} />
        </FlexCenter>
      </Grid>
      <Grid item xs={12} sm={8}>
        <h3>{data.title}</h3>
        <p>{data.pronunciation}</p>
        <p>{(data.fr)}</p>
        <p>{(data.definition)}</p>
      </Grid>

    </Grid>
  );
}


export default GlossaryWord;
