

const API = process.env.API || ''

interface Config {
  lesson: {
    list: string
    one: string
    lastLessons: string
  },
  glossary: {
    search: string
    word: string
  }
}


export const config:Config = {
  lesson: {
    list: API + '/api/lesson',
    one: API + '/api/lesson/{id}',
    lastLessons: API + '/api/last-lessons'
  },
  glossary: {
    search: API + '/api/glossary/search',
    word: API + '/api/glossary/{slug}',
  }
}