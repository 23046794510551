import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// A custom theme for this app
const darkTheme = createTheme({

  typography: {
    fontFamily: 'Raleway, "Helvetica Neue", Helvetica, Arial, sans-serif'
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#9aabff',
    },
    secondary: {
      main: '#21c7b8',
    },
    error: {
      main: red.A400,
    },

  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorDefault: '#556cd6',
        root: {
          backgroundColor: '#FFF',
          color: '#556cd6'
        }
      }
    }
  }
});

export default darkTheme;