import React from 'react';
import {Route, Routes} from "react-router-dom";
import ApplicationLayout from "../layout/app/ApplicationLayout";
import HomePage from "../pages/home/HomePage";
const LessonListPage = React.lazy(() => import('../pages/lesson/LessonListPage'));

const Router = () => {
  return (
    <ApplicationLayout>
      <React.Suspense>

        <Routes>
          <Route path="/">
            <Route index element={<HomePage />}/>
            <Route path={"/lesson/:id/edit"} element={
              <LessonListPage edit/>
            }/>
            <Route path={"/lesson/:id"} element={
              <LessonListPage/>
            }/>
            <Route path={"/lesson"} element={
              <LessonListPage/>
            }/>
            {/*<Route path="about" element={<About />} />*/}
            {/*<Route path="dashboard" element={<Dashboard />} />*/}

            {/*/!* Using path="*"" means "match anything", so this route*/}
            {/*        acts like a catch-all for URLs that we don't have explicit*/}
            {/*        routes for. *!/*/}
            {/*<Route path="*" element={<NoMatch />} />*/}
          </Route>
        </Routes>
      </React.Suspense>
    </ApplicationLayout>
  );
}


export default Router;
