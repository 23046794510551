import React from 'react';
import {useQuery} from "react-query";
import {ILessonIndex} from "../../lesson/lesson.typesdefs";
import { getLessons} from "../../lesson/api/lesson-api";
import {List, ListItem, ListItemButton, ListItemText, useTheme} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {lessonPath} from "../../lesson/navigation/navigate";
import './LessonIndex.scss'
const LessonIndex = ({small = false}:{small?:boolean}) => {
  const navigate = useNavigate()
  const theme = useTheme()

  const {data = []} =  useQuery<ILessonIndex[]>('lessons', getLessons)

  return (
    <List className={`lesson-index ${theme.palette.mode} ${small ? 'lesson-index-small' : ''}`} sx={{width: '100%', bgcolor: 'background.paper'}}>
      {data.map((lesson) => (
        <ListItem key={lesson.id}>
          <ListItemButton onClick={() => navigate(lessonPath(lesson.id))}>
            {/*<ListItemAvatar>*/}
            {/*  <Avatar>*/}
            {/*    {lesson.index}*/}
            {/*  </Avatar>*/}
            {/*</ListItemAvatar>*/}
            <div className="lesson-thumb">
              <span className="lesson-num">{lesson.index}</span>
            </div>
            <ListItemText primary={lesson.title}/>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}


export default LessonIndex;
