import React, {useState} from 'react';
import Header from "../header/Header";
//import Footer from "../footer/Footer";

import Box from "@mui/material/Box";

import {AppBar} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import './application.scss'


const ApplicationLayout = ({children}: { children: any }) => {
  const [searchOpen, setSearchOpen] = useState(false)

  const toggleDrawerHandler = (state?:boolean ) => {
    if(typeof state === 'boolean') {
      return setSearchOpen(state)
    }
    setSearchOpen((state) => !state)
  }
  return (
    <div className={`App ${searchOpen ? 'search-open' : ''}`}>
      <Box sx={{display: 'flex'}}>
        <AppBar
          elevation={0}
          position="fixed" sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
          <Header
            searchOpen={searchOpen}
            setSearchOpen={toggleDrawerHandler}
            title="Euskara" sections={[]}/>
        </AppBar>
        <Box component="main" sx={{flexGrow: 1}}>
          <Toolbar />
          {children}
          {/*<Footer*/}
          {/*  title="Plop"*/}
          {/*  description="ep!"*/}
          {/*/>*/}
        </Box>


      </Box>

    </div>
  );
}


export default ApplicationLayout;
