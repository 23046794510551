

import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// A custom theme for this app
const theme = createTheme({

  typography: {
    fontFamily: 'Raleway, "Helvetica Neue", Helvetica, Arial, sans-serif'
  },
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    mode: "dark"
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorDefault: '#556cd6',
        root: {
          backgroundColor: '#FFF',
          color: '#556cd6'
        }
      }
    }
  }
});

export default theme;